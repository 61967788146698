









































import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class CourseCard extends Vue {
  @Prop() img!: string;
  @Prop() code!: string;
  @Prop() urlCode!: string;
  @Prop() name!: string;
  @Prop() desc!: string;
  @Prop() background!: string;
  @Prop() startDate!: Date;
  @Prop() finishDate!: Date | null;
  @Prop() instructor!: string;
  @Prop() price!: number;
}
