













































































































































































import { Component, Vue } from "vue-property-decorator";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import CourseCard from "@/components/CourseCard.vue";
import firebase from "@/plugins/firebase";
import "@/types";

interface Category {
  id: string;
  name: string;
}

@Component<CourseCatalog>({
  metaInfo: {
    title: "Eğitim Kataloğu"
  },
  components: {
    Breadcrumbs,
    CourseCard
  },
  watch: {
    // options: {
    //   async handler() {
    //     await this.getCourses();
    //   }
    // }

    async selectedCategories() {
      await this.getCourses();
    }
  }
})
export default class CourseCatalog extends Vue {
  breadcrumbs = [
    {
      text: "Ana Sayfa",
      exact: true,
      to: { name: "home" }
    },
    {
      text: "Eğitim Kataloğu"
    }
  ];

  courses: Course[] = [];
  itemsPerPageArray = [10, 20, 40];
  itemsPerPage = 10;
  page = 1;
  search = "";
  filter = {};
  sortBy = "startDate";
  sortDesc = true;
  keys = [
    { label: "Eğitim Adı", value: "name" },
    { label: "Eğitmen", value: "instructor" },
    { label: "Başlangıç Tarihi", value: "startDate" }
  ];

  categories: Category[] = [];
  selectedCategories: string[] = [];
  options = {};

  get numberOfPages(): number {
    return Math.ceil(this.courses.length / this.itemsPerPage);
  }

  nextPage(): void {
    if (this.page + 1 <= this.numberOfPages) this.page += 1;
  }

  formerPage(): void {
    if (this.page - 1 >= 1) this.page -= 1;
  }

  updateItemsPerPage(x: number): void {
    this.itemsPerPage = x;
  }

  customSort(items, index, isDesc) {
    items.sort((a, b) => {
      if (index[0] == "startDate") {
        if (isDesc[0]) {
          return b[index] - a[index];
        } else {
          return a[index] - b[index];
        }
      } else {
        if (typeof a[index] !== "undefined") {
          if (!isDesc[0]) {
            return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
          } else {
            return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
          }
        }
      }
    });
    return items;
  }

  async getCourses(): Promise<void> {
    this.courses = [];

    let query = firebase
      .firestore()
      .collection("/apps/kahev-akademi/courses")
      .where("disabled", "==", false);

    if (this.selectedCategories.length > 0) {
      query = query.where(
        "categories",
        "array-contains-any",
        this.selectedCategories
      );
    }

    query = query.orderBy("name", "asc");

    const courseQs = await query.get();

    courseQs.forEach(doc => {
      const newItem = {
        id: doc.id,
        code: doc.data().code,
        urlCode: doc.data().urlCode,
        name: doc.data().name,
        desc: doc.data().desc,
        img: doc.data().img,
        instructor: doc.data().instructor,
        startDate: doc.data().startDate.toDate(),
        finishDate: doc.data().finishDate
          ? doc.data().finishDate.toDate()
          : null,
        price: doc.data().price,
        disabled: doc.data().disabled,
        categories: doc.data().categories
      };

      this.courses.push(newItem);
    });
  }

  async getAllCategories(): Promise<void> {
    this.categories = [];

    const categoryQs = await firebase
      .firestore()
      .collection("/apps/kahev-akademi/categories")
      .orderBy("name")
      .get();

    categoryQs.forEach(catDoc => {
      this.categories.push({
        id: catDoc.id,
        name: catDoc.data().name
      });
    });
  }

  async mounted(): Promise<void> {
    await this.getAllCategories();
    await this.getCourses();
  }
}
